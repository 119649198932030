
















import useEvent from "@/use/event";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    OEventSidebar: () =>
      import("@/components/organisms/event/o-event-sidebar.vue"),
  },

  setup(_, { root }) {
    const { getEventTypeColor } = useEvent({ root });

    const state = reactive({
      loading: false,
      event: {},
      modulesEnabled: {
        abstractModuleEnabled: false,
        accommodationModuleEnabled: false,
        additionalServiceModuleEnabled: false,
        budgetModuleEnabled: false,
        companyModuleEnabled: false,
        invoiceModuleEnabled: false,
        posterSessionModuleEnabled: false,
        printModuleEnabled: false,
        surveyModuleEnabled: false,
      },
    });

    const fetch = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.event = event;
          state.modulesEnabled = {
            abstractModuleEnabled: event.abstractModuleEnabled,
            accommodationModuleEnabled: event.accommodationModuleEnabled,
            additionalServiceModuleEnabled:
              event.additionalServiceModuleEnabled,
            budgetModuleEnabled: event.budgetModuleEnabled,
            companyModuleEnabled: event.companyModuleEnabled,
            invoiceModuleEnabled: event.invoiceModuleEnabled,
            posterSessionModuleEnabled: event.posterSessionModuleEnabled,
            printModuleEnabled: event.printModuleEnabled,
            surveyModuleEnabled: event.surveyModuleEnabled,
          };
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.event = {};
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetch);

    return { state, getEventTypeColor };
  },
});
